import QrCodes from "./qrCodes";
import Sidebar from "../common/sidebar";
import { useContext, useEffect, useState } from "react";
import { AuthContxt } from "../../store/authContxt";
import { Modal, ModalBody } from "reactstrap";
import Onboarding from '../../pages/onBoarding';


const Index = () => {
  const { profile } = useContext(AuthContxt);
  useEffect(()=>{
    setIsModalOpen(Object.keys(profile).length  != 0 && (!profile.demoTour2))
  },[profile])
  const toggleModal = () => setIsModalOpen(prev=>!prev);
  const [isModalOpen, setIsModalOpen] = useState(  Object.keys(profile).length != 0 && (!profile.demoTour2 ));
  return (

    <div className="w-full d-flex">
      <div className="mt-10 w-[280px] me-lg-7">
        <Sidebar />
      </div>
      <div className="customDashboardSize">
        <QrCodes />
      </div>
      <Modal isOpen={isModalOpen} toggle={toggleModal} className="custom-modal">
       
       <ModalBody>
         <Onboarding closeModal = {()=>{setIsModalOpen(false)}}/>
       </ModalBody>
      
     </Modal>
    </div>
  );
};

export default Index;
