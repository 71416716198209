import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { FormGroup } from "react-bootstrap";
import useCustomLinks from "../../../hooks/useCustomLinks";
import {
  Input,
  Label,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Spinner,
} from "reactstrap";
import { useContext, useEffect, useState } from "react";
import { generateRandomString } from "../../../utils/generateRandomString";
import { v4 as uuidv4 } from "uuid";
import { AuthContxt } from "../../../store/authContxt";
import { LinkInBioContxt } from "../linkInBioContxt";
import { PLAN, SLASH_TAG_REGEX, URL_REGEX } from "../../../utils/enum";
import { ShortLinkContxt } from "../../short-links/shortLinkContxt";
import { BLOCKED_KEYWORDS } from "../../../config/constants";

const DEFAULT_VALUES = {
  btnLabel: "",
  destinationUrl: "",
  slashTag: "",
  brandedDomain: "",
  clicks: 0,
  isActive: true,
};

export const urlBuilder = (customLink) => {
  const { domain, subDomain, spare } = customLink;
  try {
    const url = new URL(domain);
    return spare === "No" ? `${subDomain}.${url.host}` : url.host;
  } catch (e) {
    console.log("Error while creating url : ", e);
    return domain;
  }
};

const LinkForm = ({ edittedLink = null, setEdittedLink = () => {} }) => {
  const { bioPageForm } = useContext(LinkInBioContxt);
  const { shortLinks } = useContext(ShortLinkContxt);
  const { watch } = bioPageForm;
  const { links } = watch();
  const customLinks = useCustomLinks();
  const { quota } = useContext(AuthContxt);
  const isEdit = edittedLink ? true : false;
  const [addLink, setAddLink] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [destinationValue, setDestinationValue] = useState('')


  const editCount = shortLinks.reduce((acc, item) => {
    acc = item.edit ?  acc + parseInt(item.edit) : acc;
    return acc;
  }, 0)
  const messageHandler = (msg) => {
    setMessage(msg);
    setTimeout(() => {
      setMessage("");
    }, 5 * 1000);
  };

  const createLinkValidation = yup.object().shape({
    destinationUrl: yup
      .string()
      // .url("Enter a valid URL")
      .matches(URL_REGEX, "Enter a valid URL")
      .test('is-not-in-list', 'Restricted Content', (value, data) => {
        if (!value) return true; // Skip validation if value is not provided (handled by required)
        const valueLowerCase = value.toLowerCase();
        return !(BLOCKED_KEYWORDS.some(str => valueLowerCase.includes(str)) || BLOCKED_KEYWORDS.some(str => data?.parent?.title?.toLowerCase().includes(str)) || BLOCKED_KEYWORDS.some(str => data?.parent?.metaDescription?.toLowerCase().includes(str)));
      })
      .required("Destination url is required"),
    btnLabel: yup.string().required("Label is required")
    .test('is-not-in-list', 'Restricted Content', value => {
      if (!value) return true; // Skip validation if value is not provided (handled by required)
      const valueLowerCase = value.toLowerCase();
      return !BLOCKED_KEYWORDS.some(str => valueLowerCase.includes(str));
    }),
    slashTag: yup
      .string()
      .matches(SLASH_TAG_REGEX, {
        message:
          "The characters `~,<>;:'\"/\\[\\]^{}()=+!*@&$?%#|` are not allowed",
        excludeEmptyString: true,
      })
      .test('is-not-in-list', 'Restricted Content', value => {
        if (!value) return true; // Skip validation if value is not provided (handled by required)
        const valueLowerCase = value.toLowerCase();
        return !BLOCKED_KEYWORDS.some(str => valueLowerCase.includes(str));
      })
      .matches(/^\S*$/, {
        message: "Space is not allowed in slash tag",
        excludeEmptyString: true,
      })
      .required("Slash tag is required!")
      .max(50, "Slash tag can't be longer than 50 characters"),
  });

  const profileForm = useForm({
    resolver: yupResolver(createLinkValidation),
    defaultValues: DEFAULT_VALUES,
  });

  const { control, handleSubmit, reset, formState, setValue, trigger } =
    profileForm;
  const { errors } = formState;

  const cancelHandler = () => {
    setAddLink(false);
    reset();
    isEdit && setEdittedLink(null);
    setLoading(false);
  };

  const saveHandler = async (data) => {
    setLoading(true);
    if (isEdit) {
      try {
        bioPageForm.setValue(
          "links",
          links.map((item) =>
            item.id === edittedLink?.id ? { ...edittedLink, ...data } : item
          )
        );
        cancelHandler();
      } catch (e) {
        console.log("Error while updating button", e);
        setLoading(false);
      }
    } else {
      try {
        const updatedLinks = [
          {
            ...data,
            lbClicks: 0,
            type: "linkInBio",
            id: uuidv4(),
          },
          ...links,
        ];
        bioPageForm.setValue("links", updatedLinks);

        cancelHandler();
      } catch (e) {
        messageHandler(
          e?.response?.data?.data?.message ||
            e?.response?.data?.message ||
            "Something went wrong"
        );

        console.log("Error while creating button", e);
        setLoading(false);
      }
    }
  };

  // const handleUrlChange = async (e) => {
  //   const url = e.target.value;
  //   setValue("destinationUrl", url);
  //   if (isEdit) return;
  //   setValue("slashTag", generateRandomString(url));
  //   trigger("slashTag");
  // };
  const handleUrlChange = async (e) => {
    const url = e.target.value;
    setValue("slashTag", generateRandomString(url));
    setValue("destinationUrl", url);
    trigger("destinationUrl");
    trigger("slashTag");
  };

  useEffect(() => {
    if (isEdit && edittedLink !== null) {
      setAddLink(true);
      reset({
        destinationUrl: edittedLink?.destinationUrl,
        slashTag: edittedLink?.slashTag,
        brandedDomain: edittedLink?.brandedDomain,
        btnLabel: edittedLink?.btnLabel,
      });
    } else {
      reset({
        destinationUrl: "",
        slashTag: "",
        btnLabel: "",
        brandedDomain: "kut.lt",
        clicks: 0,
        isActive: true,
      });
    }
  }, [edittedLink, isEdit, reset]);

  useEffect(() => {
    setAddLink(links?.length === 0);
  }, [links]);

  return (
    <>
      <div>
        <button
          disabled={addLink}
          type="button"
          onClick={() => {
            quota?.shortlink - links?.length > 0 && setAddLink(true);
          }}
          className="btn border rounded-1 border-gray-250 bg-light text-info w-100 py-4 mx-auto fs-4 fw-bold h-lg-60px"
        >
          <i className="fa-solid fa-plus me-2"></i>
          <span> Add Link</span>
        </button>
      </div>

      {addLink ? (
        <div
          className="px-5 py-lg-8 py-5 border bg-light rounded-1 mt-lg-8 mt-6"
          style={{ borderColor: "#f3f0fb !important" }}
        >
          <FormGroup>
            <div className="d-flex justify-content-between">
            <Label className="fw-bold fs-5 mb-2">Destination URL</Label>
              {quota?.plan === PLAN.STANDARD ? (
                <div className="d-flex justify-content-end mb-3 link-info">
                  {`Remaining edit limit for destination url is ${
                    parseInt(quota?.linkEdit) - (edittedLink?.edit ? parseInt(edittedLink?.edit) : 0) < 0 ? 0 : parseInt(quota?.linkEdit) - (edittedLink?.edit ? parseInt(edittedLink?.edit) : 0)
                  }`}
                </div>
              ) : null}
            </div>
            <Controller
              name="destinationUrl"
              control={control}
              render={({ field }) => (
                <div style={{ position: 'relative', display: 'inline-block', width: '100%' }}>
                  <Input
                    {...field}
                    onChange={(e) => {
                      field.onChange(e);
                      handleUrlChange(e);
                      setDestinationValue(e.target.value)
                    }}
                    style={{
                      borderRadius: '5px 0px 0px 5px',
                      paddingRight: '30px', 
                    }}
                    disabled={(isEdit && quota?.plan === PLAN.STARTER) || (isEdit && (quota?.plan === PLAN.STANDARD && edittedLink?.edit >= quota?.linkEdit))}
                    placeholder="https://example.com/enter-long-url"
                    className="form-control rounded-1 h-50px w-100"
                  />
                  {destinationValue && <i
                    className={`fa ${errors.destinationUrl ? 'fa-times' : 'fa-check'}`}
                    style={{
                      position: 'absolute',
                      right: '10px', 
                      top: '50%',
                      transform: 'translateY(-50%)',
                      fontSize: '16px', 
                      color: errors.destinationUrl ? 'red' : 'green', 
                    }}
                  ></i>}
                </div>
              )}
            />
            {errors.destinationUrl ? (
              <div style={{ textAlign: "end" }}>
                <span className="error-text text-danger">
                  {errors.destinationUrl.message}
                </span>
              </div>
            ) : null}
          </FormGroup>

          <div className="d-lg-flex justify-content-between">
            <FormGroup className="mt-lg-10 mt-6 me-lg-3 w-lg-50">
              <Label className="fw-bold fs-5 mb-2">Button Label</Label>
              <Controller
                name="btnLabel"
                control={control}
                render={({ field }) => (
                  <>
                    <Input
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                      className="form-control rounded-1 h-50px"
                      placeholder="Home"
                    />
                  </>
                )}
              />
              {errors.btnLabel ? (
                <div style={{ textAlign: "end" }}>
                  <span className="error-text text-danger">
                    {errors.btnLabel.message}
                  </span>
                </div>
              ) : null}
            </FormGroup>

            <div className="mt-lg-10 mt-6 w-lg-50 mobile-hide">
              <label className="fw-bold fs-5 mb-2">Short Link Domain</label>
              <div className="input-group gap-3 justify-content-between mb-5 h-50px">
                <div className="custom-dropdown rounded-1 form-control p-0">
                  <div className="input-group justify-content-between h-50px">
                    <Controller
                      name="brandedDomain"
                      control={control}
                      render={({ field }) => {
                        return (
                          <div>
                            <UncontrolledDropdown direction="down">
                              <DropdownToggle
                                disabled={isEdit}
                                caret
                                color="transparent"
                                className="h-50px"
                              >
                                {field.value || "kut.lt"}
                              </DropdownToggle>
                              <DropdownMenu>
                                {customLinks &&
                                  customLinks.length > 0 &&
                                  customLinks?.map((dom) => (
                                    <DropdownItem
                                      onClick={() =>
                                        !isEdit &&
                                        field.onChange(urlBuilder(dom))
                                      }
                                    >
                                      {urlBuilder(dom)}
                                    </DropdownItem>
                                  ))}
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </div>
                        );
                      }}
                    />
                    <Controller
                      name="slashTag"
                      control={control}
                      render={({ field }) => {
                        return (
                          <div>
                            <Input
                              {...field}
                              disabled={isEdit}
                              style={{
                                width: "180px",
                                borderRadius: "0px 5px 5px 0px",
                              }}
                              className="h-50px border-top-0 border-right-0 border-bottom-0"
                              onChange={(e) => {
                                field.onChange(e);
                                trigger('slashTag')
                              }}
                              placeholder="Slash Tag"
                            />
                          </div>
                        );
                      }}
                    />
                  </div>
                  <span className="ms-1 position-absolute translate-middle-y top-50 end-0 me-2">
                    <i className="ki-duotone ki-down fs-5"></i>
                  </span>
                </div>
              </div>
              {errors.slashTag ? (
                <div style={{ textAlign: "end" }}>
                  <span className="error-text text-danger">
                    {errors.slashTag.message}
                  </span>
                </div>
              ) : null}
            </div>

            <div className="mt-lg-10 mt-6 w-lg-50 desktop-hide">
              <label className="fw-bold fs-5 mb-2">Short Link Domain</label>
              <div className="mb-5">
                <div className="custom-dropdown rounded-1 p-0">
                  <div className="">
                    <Controller
                      name="brandedDomain"
                      control={control}
                      render={({ field, fieldState }) => {
                        return (
                          <div className="bg-white border border-gray-300 rounded-1">
                            <UncontrolledDropdown direction="down">
                              <DropdownToggle
                                disabled={isEdit}
                                caret
                                color="transparent"
                                className="h-50px"
                              >
                                {field.value || "kut.lt"}
                              </DropdownToggle>
                              <DropdownMenu>
                                {customLinks &&
                                  customLinks.length > 0 &&
                                  customLinks?.map((dom) => (
                                    <DropdownItem
                                      onClick={() =>
                                        !isEdit &&
                                        field.onChange(urlBuilder(dom))
                                      }
                                    >
                                      {urlBuilder(dom)}
                                    </DropdownItem>
                                  ))}
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </div>
                        );
                      }}
                    />
                    <Controller
                      name="slashTag"
                      control={control}
                      render={({ field }) => {
                        return (
                          <div className="mt-6">
                            <Input
                              {...field}
                              disabled={isEdit}
                              className="h-50px"
                              onChange={(e) => {
                                field.onChange(e);
                              }}
                              placeholder="Slash Tag"
                            />
                          </div>
                        );
                      }}
                    />
                  </div>
                  <span className="ms-1 position-absolute translate-middle-y top-50 end-0 me-2">
                    <i className="ki-duotone ki-down fs-5"></i>
                  </span>
                </div>
              </div>
              {errors.slashTag ? (
                <div style={{ textAlign: "end" }}>
                  <span className="error-text text-danger">
                    {errors.slashTag.message}
                  </span>
                </div>
              ) : null}
            </div>
          </div>

          <div className="d-flex justify-content-end mb-3">
            {message ? (
              <span className="error-text text-danger">{message}</span>
            ) : null}
          </div>

          <div className="d-flex gap-5 mt-6 justify-content-between">
            <button
              type="button"
              onClick={cancelHandler}
              className="btn btn-light border border-gray-250 rounded-1"
            >
              Cancel
            </button>
            <button
              disabled={loading}
              type="button"
              onClick={handleSubmit(saveHandler)}
              className="btn btn-info h-50px rounded-1 w-250px"
            >
              {loading ? <Spinner /> : isEdit ? "Update" : "Save"}
            </button>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default LinkForm;
