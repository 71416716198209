import { Navigate, Outlet } from 'react-router-dom';
const checkUserAuthentication = () => {
    let localData = localStorage.getItem("user_details");
    let local = localData;
    return JSON.parse(local);
};
const AuthLayout = () => {

    const isAuthenticated = checkUserAuthentication();
    if (isAuthenticated !== null) {
        return isAuthenticated ? <Outlet /> : null; // or loading indicator, etc...
    }
    return <Navigate to={"/"} replace />;
};
export default AuthLayout;