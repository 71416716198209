import httpMethods from "..";


const ContactApi = {
    createContact: async (payload) => {
        const token = localStorage?.getItem('auth_token');
        return httpMethods
            .post(`/contact`, payload, {
                headers: { Authorization: `Bearer ${token}` },
            })
    },
    bulkCreateContact: async (payload) => {
        const token = localStorage?.getItem('auth_token');
        return httpMethods
            .post(`/contact/bulk`, payload, {
                headers: { Authorization: `Bearer ${token}` },
            })
    },
    updateContact: async (payload) => {
        const token = localStorage?.getItem('auth_token');
        const contactId = payload.contactId
        return httpMethods
            .put(`/contact/${contactId}`, payload, {
                headers: { Authorization: `Bearer ${token}` },
            })
    },
    updateBulkContact: async (payload) => {
        const token = localStorage?.getItem('auth_token');
        return httpMethods
            .put(`/contact/bulk-update`, payload, {
                headers: { Authorization: `Bearer ${token}` },
            })
    },
    getContacts:  async (payload) => {
        const token = localStorage?.getItem('auth_token');

        const {userId, page, limit, search, order, orderColumn} = payload;

        // Create the base query string with the required parameters
        let query = `userId=${userId}&search=${encodeURIComponent(search || '')}&order=${encodeURIComponent(order || 'updatedAt')}&orderColumn=${encodeURIComponent(orderColumn || 'updatedAt')}&page=${encodeURIComponent(page || '0')}&limit=${encodeURIComponent(limit || '20')}`;
    
        // Append tags as separate parameters
        if (payload.tags && payload.tags.length > 0) {
            payload.tags.forEach(tag => {
                query += `&tags=${encodeURIComponent(tag)}`;
            });
        }
    
        // Make the HTTP GET request with the constructed URL
        return httpMethods
            .get(`/contact?${query}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
    },
    deleteContacts:  async (userId,payload) => {
        const token = localStorage?.getItem('auth_token');
        console.log(payload);
        return httpMethods
            .put(`/contact/bulk-delete?userId=${userId}`,{contacts: payload}, {
                headers: { Authorization: `Bearer ${token}` },
            })
    },
}

export default ContactApi;
