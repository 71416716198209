import React, { useContext, useEffect, useState } from 'react';
import imageclap from '../assets/media/giphy.webp'
import Features from '../assets/media/features.png'
import { useNavigate } from 'react-router-dom';
import { AuthContxt } from '../store/authContxt';
import UserSettingsApi from '../services/http/userSettings';

const CardComponent = () => {
  const { fetchProfile, profile } = useContext(AuthContxt);
  const navigate = useNavigate();
  const [Profile ,setProfile] = useState()
  const [loading, setLoading] = useState(false)
  const SkipDemo = async (demo
  ) => {
    setLoading(true);
    try {
      const response = await UserSettingsApi.skipDemo({skip: demo});
      setProfile(response?.data?.data || {});
      fetchProfile();
      setLoading(false);
    } catch (e) {
      console.log('Error while fetching user dashboard analytics: ', e);
      setLoading(false);
    }
  };

  useEffect(()=>{
    setProfile(profile)
  },[profile])

  return (
    <div className="container">
      <div className="welcome-card d-flex align-items-center justify-content-start py-4 rounded">
        <div className='image-clap'>
            <img src={imageclap} alt='iamge clapping' style={{width: '150px',    marginLeft: '40px'}} />
        </div>
        <div className='ms-5'>
        <h1 style={{fontSize: '32px', fontWeight: 'bold'}}>Welcome to ShortLink App!</h1>
        <p style={{fontSize: '18px'}}>Your one-stop solution for managing and shortening URLs.  Stay organized, boost your engagement, and enhance your digital strategy with ShortLink App. Get started today and experience seamless link management like never before.</p>

        </div>
        
      </div>
      <div className="main-content d-flex align-items-center justify-content-start">
        <div className="left-cards me-5 mt-5 w-40" style={{width: '33%'}}>
        
            <div className={`small-card bg-light p-5 mb-4 rounded ${Profile?.demoTour1 ? "active": "inactive"}`}>
              <h2>Short links </h2>
              <p>Click the Create button to make your first shortlink.
              Experience the ease of generating shortlinks effortlessly!</p>
              <div className="button-group d-flex justify-content-end my-5">
                <button className={`btn btn-secondary me-4 `} disabled={Profile?.demoTour1} onClick={()=>{SkipDemo("demoTour1")}}>Skip</button>
                <button className="btn btn-info" disabled={Profile?.demoTour1} onClick={()=>{navigate("/short-links")}}>Create</button>
              </div>
            </div>
            <div className={`small-card bg-light p-5 mb-4 rounded ${Profile?.demoTour2 ? "active": "inactive"}`}>
              <h2>QR Codes </h2>
              <p>Create your first QR code easily with Divsly.
              Simplify your sharing process effortlessly today!</p>
              <div className="button-group d-flex justify-content-end my-5">
              <button className={`btn btn-secondary me-4 `} disabled={Profile?.demoTour2} onClick={()=>{SkipDemo("demoTour2")}}>Skip</button>
              <button className="btn btn-info" disabled={Profile?.demoTour2} onClick={()=>{navigate("/qr-codes")}}>Create</button>
              </div>
            </div>
            <div className={`small-card bg-light p-5 mb-4 rounded ${Profile?.demoTour3 ? "active": "inactive"}`}>
              <h2>Link in Bio </h2>
              <p>
Create your first link in bio page with Divsly.
Simplify your sharing process effortlessly today!</p>
              <div className="button-group d-flex justify-content-end my-5">
              <button className={`btn btn-secondary me-4 `} disabled={Profile?.demoTour3} onClick={()=>{SkipDemo("demoTour3")}}>Skip</button>
              <button className="btn btn-info" disabled={Profile?.demoTour3} onClick={()=>{navigate("/link-in-bio")}}>Create</button>
              </div>
            </div>
         
        </div>
        <div className="large-card ms-5 mt-5 p-5 bg-light rounded   w-60" style={{width: '65%', background: 'url(../assets/media/features.png)'}} >
        
        <h2>Shortlink</h2>
<p>Shortlinks are shortened URLs that make sharing and managing links easier. They can be customized and tracked for valuable insights on click rates, locations, and devices used.</p>

<h2>QR Code</h2>
<p>QR codes are scannable barcodes that direct users to specific URLs or digital content. They are versatile and can be customized with logos and colors to align with brand identity.</p>

<h2>Link in Bio</h2>
<p>A link in bio page consolidates multiple links into one place for social media profiles. It allows users to share various online resources from a single, easily accessible link.</p>

<h2>Analytics</h2>
<p>Analytics tools provide insights into the performance of shortlinks, QR codes, and link in bio pages. They offer detailed statistics on clicks, user engagement, and demographic data.</p>

<h2>Campaigns</h2>
<p>Campaigns help track and manage marketing efforts by organizing multiple shortlinks and QR codes under a single campaign. This allows for detailed performance analysis and comparison.</p>

<h2>Integrations</h2>
<p>Integrations connect shortlink and QR code tools with other platforms and services, such as Google Analytics, social media, and CRM systems. This ensures seamless data flow and enhanced functionality.</p>

        </div>
      </div>
    </div>
  );
};

export default CardComponent;
