import { createContext, useEffect, useState } from "react";
import UserSettingsApi from "../services/http/userSettings";
import SubscriptionApi from "../services/http/subscription";
import generateProfileImage from "../utils/generateProfileImage";
import { useLocation } from "react-router-dom";

export const AuthContxt = createContext(null);

const AuthContxtProvider = (props) => {
    const location = useLocation();

    const [profile, setProfile] = useState({});
    const [quota, setQuota] = useState();

    const logout = () => {
        setProfile(null)
        localStorage.removeItem("user_details");
        localStorage.removeItem("auth_token");
    };

    const profileHandler = (data) => {
        setProfile(preState => ({ ...preState, ...data }));
    }

    const fetchProfile = async () => {
        try {
            const { data } = await UserSettingsApi.fetchProfile();
            const response = await UserSettingsApi.getLocation();
            let profileDetails = data?.data;
            profileDetails = { 
                ...profileDetails,
                mobile: profileDetails?.mobile ? profileDetails.mobile : '',
                ...data?.country ? {country: data.cuntry} : {country: response?.data?.data?.countryCode}
            }
            console.log(profileDetails, 'profileDetails')
            if (!profileDetails?.avatar) {
                profileDetails = { 
                    ...profileDetails,
                    avatar: generateProfileImage(profileDetails.email)
                }
            }
            setProfile(profileDetails);
            return profileDetails;
        }
        catch (e) {
            console.log("Error while fetching profile: ", e)
        }
    };

    const fetchQuota = async () => {
        const token = localStorage?.getItem('auth_token');
        try {
            const { data } = await SubscriptionApi.getCurrentQuota();
            setQuota(data?.data);
        }
        catch (e) {
            console.log("Error while fetching quota: ", e)
        }
    };

    useEffect(() => {
        if (localStorage.getItem("auth_token")) {
            fetchProfile();
            fetchQuota();
        }
    }, [location]);

    return (
        <AuthContxt.Provider value={{
            profile,
            setProfile,
            profileHandler,
            fetchProfile,
            fetchQuota,
            logout,
            quota
        }}>
            {props.children}
        </AuthContxt.Provider>
    );
}

export default AuthContxtProvider;