import { Offcanvas } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { createLinkValidation } from "../../../utils/validations/creatLinkValidation";
import { useContext, useState } from "react";
import AddNewShortLinkQR from "./addNewQr";
import AddNewUTM from "./addNewUTM";
import AddNewTitleLink from "./addNewTitleLink";
import AddNewTagLinks from "./addNewTagLinks";
import AddNewButtons from "./addNewButtons";
import ShortLinksAPI from "../../../services/http/shortLinks";
import DestinationURL from "./destinationUrl";
import { AuthContxt } from "../../../store/authContxt";
import { ShortLinkContxt } from "../shortLinkContxt";
import DrawerWrapper from "../../wrapper/drawerWrapper";

const DEFAULT_VALUES = {
  tags: "",
  url: "",
  title: "",
  destinationUrl: "",
  brandedDomain: "kut.lt",
  slashTag: "",
  expirationDate: "",
  utm_source: "",
  utm_medium: "",
  utm_campaign: "",
  utm_id: "",
  utm_term: "",
  utm_content: "",
  isQrGenerated: false,
  isLinkInBio: false,
  bioPage: "",
  password: ""
};

const AddNewShortLink = ({ isAddNewLinkOpen, setIsAddNewLinkOpen }) => {
  const { getShortLinks } = useContext(ShortLinkContxt);
  const { fetchQuota } = useContext(AuthContxt);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const auth_token = localStorage.getItem("auth_token");

  const form = useForm({
    resolver: yupResolver(createLinkValidation),
    defaultValues: DEFAULT_VALUES,
  });
  const { control, handleSubmit, reset } = form;
  const [isURLParamBuilderOpen, setIsURLParamBuilderOpen] = useState(false);

  const messageHandler = (msg) => {
    setMessage(msg);
    setTimeout(() => {
      setMessage("");
    }, 5 * 1000);
  };

  const onSubmit = async (data) => {
    if (data) {
      data.title = data.title.trim();
      setLoading(true);
      if (data.tags) {
        const uniqueArray = data.tags.split(',').filter((value, index, self) => {
          return value.trim() && self.indexOf(value.trim()) === index;
        });
        data.tags = uniqueArray.join(',')
      }
      ShortLinksAPI.addNewShortLink(auth_token, {
        ...data,
        faviconUrl: ""
      })
        .then((response) => {
          setIsAddNewLinkOpen(false);
          getShortLinks();
          fetchQuota();
          reset();
        })
        .catch((error) => {
          messageHandler(
            error?.response?.data?.data?.message ||
              error?.response?.data?.message ||
              "Something went wrong"
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const header = (
    <Offcanvas.Header className="px-lg-20 border-bottom" closeButton>
      <h2 className="fw-bold">Add New Link</h2>
    </Offcanvas.Header>
  );

  return (
    <DrawerWrapper
      open={isAddNewLinkOpen}
      toggle={() => {
        reset();
        setIsAddNewLinkOpen(false);
      }}
      header={header}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <DestinationURL
          form={form}
          setIsURLParamBuilderOpen={setIsURLParamBuilderOpen}
        />

        <AddNewTitleLink control={control} form={form} />

        <AddNewShortLinkQR form={form} />

        <AddNewTagLinks control={control} />

        <div className="d-flex justify-content-end mb-3">
          {message ? (
            <span className="error-text text-danger">{message}</span>
          ) : null}
        </div>

        <AddNewButtons loading={loading} />
      </Form>
      <AddNewUTM
        isURLParamBuilderOpen={isURLParamBuilderOpen}
        setIsURLParamBuilderOpen={setIsURLParamBuilderOpen}
        form={form}
      />
    </DrawerWrapper>
  );
};

export default AddNewShortLink;
