import React, { useState, useContext, useEffect, useRef } from "react";
import { Spinner } from "reactstrap";
import {
  Card,
  CardBody,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
} from "reactstrap";

import _ from 'lodash';

import 'react-tagsinput/react-tagsinput.css'; // For tags input styling

import ContactsForm from "../components/common/ContactsForm";
import Sidebar from "../components/common/sidebar";
import FileUpload from "../components/common/FileUpload";

import { AuthContxt } from "../store/authContxt";

import ContactApi from "../services/http/contact";
import SearchFilters from "../components/contact/SearchFilters";
import ContactTable from "../components/contact/ContactTable";
import BulkActionList from "../components/contact/BulkActionList";
import DeleteModal from "../components/contact/DeleteModal";
import TagModal from "../components/contact/TagModal";
import TagsApi from "../services/http/tags";

const apiEndpoint = "";

const sortFilters = [
  {filter: "asc", label: "A - Z"},
  {filter: "desc", label: "Z - A"}
]

const Contacts = () => {
  const [isActive, setIsActive] = useState(false);
  const [searchHide, setSearchHidee] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [formOpen, setFormOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showTagModal, setShowTagModal] = useState(false);
  const [tags, setTags] = useState([]);
  const [newTag, setNewTag] = useState('');
  const [allContacts, setAllContacts] = useState([]);
  const [reload, setReload] = useState(false);
  const [contactForUpdate, setContactForUpdate] = useState(null);
  const [selectedContact, setSelectedContact] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [contactForDelete, setContactForDelete] = useState(null);
  const [singleDeleteModal, setSingleDeleteModal] = useState(false);
  const [userTags, setUserTags] = useState([]);

  const [searchText, setSearchText] = useState('');
  const [searchDebouncedText, setSearchDebouncedText] = useState('');
  const [selectedTags, setSelectedTags] = useState([]);
  const [sortOrder, setSortOrder] = useState('desc');
  const [sortColumn, setSortColumn] = useState('updatedAt')
  // New states for pagination
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(20);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [updateTagModalLoading, setUpdateTagModalLoading] = useState(false);
  const [deleteSingleContactLoading, setDeleteSingleContactLoading] = useState(false);
  const [deleteManyContactLoading, setDeleteManyContactLoading] = useState(false);

  const [infinitePageLoading, setInfinitePageLoading] = useState(false);
  let userData = localStorage?.getItem('user_details');
  const { profile } = useContext(AuthContxt);
  const { id } = profile || {};

  useEffect(() => {

    const getUserTags = async () => {
      try {
        if(formOpen) return;
        let payload = {
          userId: id,
          search: searchText,
          order: 'asc',
        };

        const response = await TagsApi.getTags(payload);

        if (response.status === 200) {
          let tags = response?.data || [];
          tags = tags?.map(tag => tag.name)
          setUserTags(tags);
        }
      } catch(error) {
        console.log(error)
      }
    }

    const getAllContacts = async () => {
      if (isLoading || formOpen) return;
      setIsLoading(true);

      if(page > 0) {
        setInfinitePageLoading(true);
      }
      try {
        let payload = {
          userId: id,
          search: searchText,
          tags: selectedTags,
          order: sortOrder,
          orderColumn: sortColumn,
          page: page,
          limit: limit,
        };
        const response = await ContactApi.getContacts(payload);
        if (response.status === 200) {

          let contacts = response?.data || [];
          if (contacts.length < limit) {
            setHasMore(false);
          }

          setAllContacts(prevContacts => {
            const updatedContacts = [...prevContacts, ...contacts];
            console.log("unique ",_.uniqBy(updatedContacts, 'id'))
            return _.uniqBy(updatedContacts, 'id');
          });
          setIsLoading(false);
          setInfinitePageLoading(false);
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
        setInfinitePageLoading(false);
      } finally {
        setIsLoading(false);
        setInfinitePageLoading(false);
      }
    };

    if (id) {
      getAllContacts();
      getUserTags();
    }
  }, [profile, reload, searchText, selectedTags, sortOrder, showTagModal, page]);

  useEffect(() => {
    setSelectAll((allContacts.length > 0) && (selectedContact.length === allContacts.length));
    setIsActive((selectedContact.length > 0));
  }, [selectedContact, reload, formOpen, selectedTags]);
  
  // Handlers for modals
  const toggleDeleteModal = () => setShowDeleteModal(!showDeleteModal);
  const toggleTagModal = () => {
    setTags([]);
    setShowTagModal(!showTagModal)
  };
  const toggleSingleDeleteModal = () => setSingleDeleteModal(!singleDeleteModal);

  const handleResetPage = () => {
    setSelectedContact([])
    setAllContacts([]);
    setPage(0);
    setIsLoading(false);
    setHasMore(true);
  }

  //Handlers for search filters
  const handleTagsChange = (tagsInput) => {
    const splitTags = tagsInput.join(',').split(',').map(tag => tag.trim()).filter(tag => tag !== '');
    const uniqueTags = [...new Set(splitTags)];
    setTags(uniqueTags);
  }

  const timeoutRef = useRef(null);

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchDebouncedText(value);
    
    // Clear the previous timeout
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    // Set a new timeout
    timeoutRef.current = setTimeout(() => {
      setSelectedContact([])
      setSearchText(value);
      handleResetPage()
    }, 1000);
  };

  const handleSortChange = (order) => {
    setSelectedContact([])
    setSortColumn("firstName")
    setSortOrder(order);
    setReload(!reload);
    handleResetPage()
  }

  const handleTagChange = (tag) => {
    setSelectedContact([])
    setSelectedTags(prevTags =>
      prevTags.includes(tag) ? prevTags.filter(t => t !== tag) : [...prevTags, tag]
    );
    handleResetPage();
  };

  // Function to toggle the icon state
  const toggleIcon = (event) => {
    const checked = event.target.checked;
    setIsActive(checked);
    setIsActive(!isActive);
  };

  const toggleSearch = () => setSearchHidee(!searchHide);
  const toggleCanvas = () => setIsOpen(!isOpen);
  const toggleForm = () => setFormOpen(!formOpen);

  const handleContactUpdate = (contact) => {
    setContactForUpdate(contact);
    toggleForm();
  }

  const isContactSelected = (contact) => {
    const id = contact.id;
    return selectedContact.some((selected) => selected?.id === id);
  };

  const handleCheckboxChange = (contact) => {
    console.log(contact)
    console.log(isContactSelected(contact))
    const updatedSelectedContacts = isContactSelected(contact)
    ? selectedContact.filter((c) => c?.id !== contact.id)
    : [...selectedContact, contact];
    setSelectedContact(updatedSelectedContacts);
  };

  const handleSelectAllChange = (event) => {
    const checked = event.target.checked;
    if (checked) {
      setSelectedContact(allContacts);
    } else {
      setSelectedContact([]);
    }
    setSelectAll(checked);
  };

  const handleSingleContactDelete = async () => {
    try {
      setDeleteSingleContactLoading(true);
      const userId = JSON.parse(userData)?.id
      const response = await ContactApi.deleteContacts(userId, [contactForDelete]);
      if(response.status === 200) {
        if(selectedContact.includes(contactForDelete)) {
          const updatedSelectedContacts = selectedContact.filter((c) => c !== contactForDelete);
          setSelectedContact(updatedSelectedContacts);
        }
        toggleSingleDeleteModal()
        setContactForDelete(null);
        setSelectedTags([]);
        handleResetPage();
        setReload(!reload);
        setDeleteSingleContactLoading(false);
      }
      setDeleteSingleContactLoading(false);
    } catch (error) {
      console.log(error);
      setDeleteSingleContactLoading(false);
    }
  }

  const handleContactsDelete = async () => {
    try {
      setDeleteManyContactLoading(true);
      const userId = JSON.parse(userData)?.id
      const response = await ContactApi.deleteContacts(userId, selectedContact);
      if(response.status === 200) {
        setSelectedContact([]);
        setIsActive(false);
        setSelectAll(false);
        toggleDeleteModal();
        handleResetPage();
        setSelectedTags([]);
        setDeleteManyContactLoading(false);
        setReload(!reload);
      }
      setDeleteManyContactLoading(false);
    } catch (error) {
      setDeleteManyContactLoading(false);
      console.log(error);
    }
  }

  const handleRelod = () => {
    setReload(!reload);
    handleResetPage();
  }

  const handleUploadFileCanvas = (status) => {
    setIsOpen(!isOpen);
    // handleResetPage();
  }

  const openCreateForm = () => {
    setContactForUpdate(null)
    toggleForm()
  }

  const handleBulkUpdate = async () => {
    try {
        setUpdateTagModalLoading(true);
        const userId = JSON.parse(userData)?.id
        // const contacts = selectedContact?.map((contact) => {
        //   let tags = contact?.tags?.map(tag => tag.tag.name)
        //   return {...contact,tags}
        // })

        const contacts = selectedContact?.map((contact) => ({id: contact.id}))
        const response = await ContactApi.updateBulkContact({userId, contacts, tags});
        if(response.status === 200) {
          setTags([]);
          setUpdateTagModalLoading(false);
          setSelectedContact([]);
          setIsActive(false);
          setSelectAll(false);
          setSelectedTags([]);
          handleResetPage()
          toggleTagModal();
          setReload(!reload);
        }
        setUpdateTagModalLoading(false);
      } catch (error) {
        setUpdateTagModalLoading(false);
        console.log(error);
      }
  }

  const handleScroll = (e) => {
    if (isLoading || !hasMore) return;
  
    // e.target is the element that is scrolling
    const { scrollTop, scrollHeight, clientHeight } = e.target;
  
    if (scrollHeight - scrollTop <= clientHeight * 1.5) {
      setPage(prevPage => prevPage + 1);
    }
  };
  
  return (
    <div >
      <div className="w-full d-flex">
        <div className="mt-md-10 w-[280px] me-md-7 mt-xs-0 me-xs-0">
          <Sidebar />
        </div>
        <div className="customDashboardSize">
          <div className="d-flex flex-column w-100 flex-root app-root">
            <div className="app-page flex-column flex-column-fluid">
              <div className="app-wrapper flex-column flex-row-fluid pe-3 pe-lg-0 me-lg-10 mt-lg-10 mt-0 position-relative">
                <div className="app-main flex-column flex-row-fluid">
                  <Card className="card card-flush">

                    {/* Header section */}
                    <div className="px-6 px-lg-10 py-lg-10 py-5 d-flex items-center justify-content-between w-100 align-items-center">
                      <h2 className="me-15 fs-2x">Contacts</h2>
                      <div className="card-toolbar justify-content-center align-items-center gap-5 me-0 mb-0 h-10 cardToolbarIcons">
                        
                        {/* Search bar and filters section */}
                        <SearchFilters
                          searchText = {searchDebouncedText}
                          handleSearchChange = {handleSearchChange}
                          searchHide = {searchHide}
                          toggleSearch = {toggleSearch}
                  
                          tags = {userTags}
                          handleTagChange = {handleTagChange}
                          selectedTags = {selectedTags}
                  
                          sortFilters = {sortFilters}
                          handleSortChange = {handleSortChange}
                        />
                        
                        <button type="button"  onClick={toggleCanvas} title="Bulk Import" className="btn btn-icon rounded-1 bg-light-info px-4"><i className="fa-solid fa-arrow-right-to-bracket fa-rotate-90 text-info fs-3"></i></button>
                        <button
                          type="button"
                          className="btn btn-icon rounded-1 btn-info px-4"
                          color="danger"
                          onClick={openCreateForm}
                        >
                          <i className="fa-solid fa-plus text-white fs-3"></i>
                        </button>
                      </div>
                    </div>
                    {selectedContact.length > 0 &&  <h5 className="px-10 me-15">Selected contacts : {selectedContact.length}</h5>}
                    {id ? 
                      <CardBody 
                        className="pt-0" 
                        style={{ width:"100%", overflowY: "scroll" }}
                        onScroll={handleScroll}
                      >
                        
                        {/* Contact table */}
                        <ContactTable 
                          selectAll = {selectAll}
                          handleSelectAllChange = {handleSelectAllChange}
                          
                          allContacts = {allContacts}
                          isContactSelected = {isContactSelected}
                          selectedContact = {selectedContact}
                          handleCheckboxChange = {handleCheckboxChange}
                  
                          handleContactUpdate = {handleContactUpdate}
                  
                          setContactForDelete = {setContactForDelete}
                          toggleSingleDeleteModal = {toggleSingleDeleteModal}
                  
                          openCreateForm = {openCreateForm}
                          
                          search = {searchText}
                          tags = {selectedTags}

                          isLoading={isLoading}
                          infinitePageLoading={infinitePageLoading}
                        />
                      </CardBody> : 
                      <div className="card-toolbar justify-content-center align-items-center gap-5 me-0 mb-0 h-10 cardToolbarIcons" style={{height:"65vh"}}>
                        <Spinner/>
                      </div>
                    }
                  </Card>

                  {/* Bulk operation action list  */}
                  <BulkActionList 
                    isActive = {isActive}
                    toggleTagModal = {toggleTagModal}
                    toggleDeleteModal = {toggleDeleteModal}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Create contact form canvas */}
        <Offcanvas isOpen={formOpen} toggle={toggleForm}  className="custom-canvas-width" direction='end'>
          <OffcanvasHeader className='mb-4 mx-4 mt-2 border-bottom' toggle={toggleForm}>
            {contactForUpdate && <h3 className='font-weight-bold'>Update Contact </h3>}
            {!contactForUpdate && <h3 className='font-weight-bold'>Create Contact </h3>}
          </OffcanvasHeader>
          <OffcanvasBody>

            {/* Contact form for create and update option*/}
            <ContactsForm toggleForm={toggleForm} reload={reload} setReload={setReload} userId={id} contactForUpdate={contactForUpdate} setContactForUpdate={setContactForUpdate} handleResetPage={handleResetPage} setSelectedTags={setSelectedTags} setSortOrder={setSortOrder} />
          </OffcanvasBody>
        </Offcanvas>
        
        {/* Bulk contact import canvas */}
        <Offcanvas isOpen={isOpen} toggle={toggleCanvas} className="custom-canvas-width" direction='end'>
            <OffcanvasHeader className='mb-4 mx-4 mt-2 border-bottom' toggle={toggleCanvas}>
                <h3 className='font-weight-bold'> Upload Products</h3>
            </OffcanvasHeader>
            <OffcanvasBody>
                <FileUpload apiEndpoint={apiEndpoint} handleUploadFileCanvas = {handleUploadFileCanvas} handleRelod = {handleRelod}/>
            </OffcanvasBody>
        </Offcanvas>

        <div>
            {/* Delete Modal for single and multiple contacts */}
            <DeleteModal 
              singleDeleteModal = {singleDeleteModal}
              toggleSingleDeleteModal = {toggleSingleDeleteModal}
              handleSingleContactDelete = {handleSingleContactDelete}
      
              showDeleteModal = {showDeleteModal}
              toggleDeleteModal = {toggleDeleteModal}
              handleContactsDelete = {handleContactsDelete}
              deleteSingleContactLoading={deleteSingleContactLoading}
              deleteManyContactLoading={deleteManyContactLoading}
            />

            {/* Tag Modal for multiple selected contacts*/}
            <TagModal 
              showTagModal = {showTagModal}
              toggleTagModal = {toggleTagModal}
              
              tags = {tags}
              handleTagsChange = {handleTagsChange}
              updateTagModalLoading = {updateTagModalLoading}
              handleBulkUpdate={handleBulkUpdate}
            />
        </div>
      </div>
    </div>
  );
};

export default Contacts;