import { createContext, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { getRandomColor } from "../../utils/imageFromUsername";
import { AuthContxt } from "../../store/authContxt";
import generateProfileImage from "../../utils/generateProfileImage";

const DEFAULT_VALUES = {
  category: "",
  goal: [],

  template: "",
  theme: "theme1",

  pageDomain: "bio.kut.lt",
  pageLink: "",

  avatar: "",
  name: "",
  bio: "",

  bg: "/layout-placeholder.jpg",
  bgType: "image",
  bottomBg: "",
  bottomBgType: "",
  gradient: "",
  gradientClr1: "#555555",
  gradientClr2: "#dddddd",
  gradientDir: "0",
  bGradientClr1: "#555555",
  bGradientClr2: "#dddddd",
  bGradientDir: "0",
  preset: "#000000",
  bgClr: "",
  font: "Helvetica Neue",
  hideLogo: false,
  generatedAvatar: 'yes',
  socials: [],

  logo: "",
  links: [],

  fontClr: "",
  button: "",
  btnClr: "",
  shadowClr: "",
  descriptionClr: "",
  titleClr: "",
};

export const LinkInBioContxt = createContext(null);

const LinkInBioContxtProvider = ({ children }) => {
  const { fetchQuota } = useContext(AuthContxt);
  const [currentScreen, setCurrentScreen] = useState(1);
  const [isEdit, setIsEdit] = useState();
  const bioPageForm = useForm({
    resolver: yupResolver({}),
    defaultValues: DEFAULT_VALUES,
  });

  const { reset } = bioPageForm;

  const screenHandler = (screen, data = null) => {
    setCurrentScreen(screen);
    if (data) {
      setIsEdit(true);
      editLinkInBioHandler(data);
    } else {
      setIsEdit(false);
    }
  };

  const editLinkInBioHandler = (data) => {
    reset({
      ...data,
      font: data?.font || "Helvetica Neue",
      avatar: data?.avatar || generateProfileImage(data?.name || data?.pageLink),
    });
  };

  useEffect(() => {
    fetchQuota();
  }, []);

  return (
    <LinkInBioContxt.Provider
      value={{
        bioPageForm,
        currentScreen,
        screenHandler,
        isEdit,
        setIsEdit,
      }}
    >
      {children}
    </LinkInBioContxt.Provider>
  );
};

export default LinkInBioContxtProvider;
